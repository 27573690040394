import { useEffect, useState } from 'react'
import {
	AddOrderResponse,
	AddOrderRequestModel,
	AddOrderSKUsModel,
	BetaObject,
	DataResponse,
	OrderSummaryResponse,
	SkuDetailsResponse,
	OrderTermModel,
} from '../../../../../../../utils/interfaces/APIModels'
import {
	GenericColumn,
	OrderSummaryDisplay,
} from '../../../../../../../utils/interfaces/ComponentModels'
import { usePostBetaObjectAddOrderMutation } from '../../../../../../../services/proxyAPIData'
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
	styled,
	useTheme,
} from '@mui/material'
import {
	getOrderTerm,
	isEmptyOrWhitespace,
	showErrorToast,
	showSuccessToast,
	toAlphaString,
	toBeta,
} from '../../../../../../../utils/helperFunctions/helperFunctions'
import './AddOrderSummary.scss'
import Icon from '@mdi/react'
import { mdiTextBoxEditOutline } from '@mdi/js'
import { StyledButton } from '../../../../../../../styles/styledComponents/inputs/StyledButton'
import UseCrud from '../../../../../../../utils/customHooks/APICalls/UseCrud'
import {
	Currencies,
	OrderSKUAutoRenewTerm,
	ReturnTypes,
} from '../../../../../../../utils/enums/enums'
import {
	Currency,
	CustomerPartner,
	FXRate,
	OrderSKU,
} from '../../../../../../../utils/interfaces/DBModels'
import LoadingBox from '../../../../../displays/LoadingBox/LoadingBox'
import { AddOrderSummaryTable } from '../AddOrderSummary/AddOrderSummaryTable/AddOrderSummaryTable'
import { useAppDispatch } from '../../../../../../../store/hooks'
import { setPartnerIDToUse } from '../../../../../../../store/reducers/reducers'
import { ErrorHandling } from '../../../../../../../utils/customHooks/ErrorHandling/ErrorHandling'
import { MonthsInAYear } from '../../../../../../../utils/constants/constants'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment'

const AddOrderSummary = ({
	orderSummaryResponse,
	partnerID,
	customerPartnerReference,
	isCoTerm,
	hasOrderNotes,
	orderNotes,
	previousSKUTerm,
	previousSKUActivationDate,
	handleCloseOrderFormModal,
}: {
	orderSummaryResponse: OrderSummaryResponse
	partnerID: number
	customerPartnerReference: string
	isCoTerm: boolean
	hasOrderNotes: boolean
	orderNotes: string
	skusforPreviousOrderIDList: OrderSKU[]
	previousSKUTerm: OrderTermModel
	previousSKUActivationDate: Date | undefined
	handleCloseOrderFormModal: any
}) => {
	const dispatch = useAppDispatch()
	const addErrorLog = ErrorHandling()

	//skuDetails table
	const [currentOrderSummaryInfo, setCurrentOrderSummaryInfo] = useState(
		[] as SkuDetailsResponse[]
	)
	const [originalOrderSummaryInfo, setOriginalOrderSummaryInfo] = useState(
		[] as SkuDetailsResponse[]
	)

	//Dialog
	const [openDialog, setOpenDialog] = useState(false)
	const [dialogTitle, setDialogTitle] = useState('')
	const [dialogDescription, setDialogDescription] = useState('')

	//FX Variables
	const [baseCurrencyID, setBaseCurrencyID] = useState(0)
	const [orderCurrencyID, setOrderCurrencyID] = useState(0)
	const [displayCurrencyList, setDisplayCurrencyList] = useState(
		[] as Currency[]
	)
	const [fxRateList, setFXRateList] = useState([] as FXRate[])
	const [originalOrderCurrencyID, setOriginalOrderCurrencyID] = useState(0)
	const [isMrcNrcChanging, setIsMrcNrcChanging] = useState(false)
	const [isCurrencyRadioDisabled, setIsCurrencyRadioDisabled] = useState(false)
	const [disableOrderConfirmation, setDisableOrderConfirmation] =
		useState(false)

	//API calls
	const [isLoading, setIsLoading] = useState(false)
	const [addOrder] = usePostBetaObjectAddOrderMutation()
	const [dataCallMade, setDataCallMade] = useState(false)
	const [apiCallsDone, setAPICallsDone] = useState(false)

	// Hooks
	const { fetchData } = UseCrud()

	//addOrderSummary input validation
	const [mrcNrcField, setMrcNrcField] = useState('')
	const [mrcNrcValue, setMrcNrcValue] = useState('')
	const [mrcNrcRowChanged, setMrcNrcRowChanged] = useState('')
	const mrcPerUnitCheck = 'MRCPerUnit'
	const nrcPerUnitCheck = 'NRCPerUnit'

	// Theme
	const theme = useTheme()

	//footer info styling
	const InfoAlert = styled(Alert)({
		'& .MuiAlert-icon': {
			color: 'black',
		},
		backgroundColor: '#f5f5f5',
		color: 'black',
		fontSize: '14px',
		textAlign: 'center',
		display: 'flex',
		margin: 'auto',
	})

	useEffect(() => {
		// Currency/FXRate Calls, Make call if not already made
		if (!dataCallMade) {
			setDataCallMade(true)
			getFXData()
		} else {
			if (apiCallsDone) {
				if (orderSummaryResponse.SkuDetails) {
					var skuDetailsWithIds = orderSummaryResponse.SkuDetails.map(
						(sku, index) => {
							var updatedSkuTerm = ''
							if (isCoTerm && Number(sku.MRC) > 0) {
								updatedSkuTerm = getOrderTerm(
									Number(previousSKUTerm.Years),
									Number(previousSKUTerm.Months)
								)
							} else {
								if (
									typeof sku.SkuTerm === 'number' &&
									Number(sku.SkuTerm) > 0
								) {
									updatedSkuTerm =
										orderSummaryResponse.OrderType === 'New' ||
										orderSummaryResponse.OrderType === 'Renewal' ||
										Number(sku.SkuTerm) >= MonthsInAYear
											? `${Number(sku.SkuTerm) / MonthsInAYear} Years`
											: `${sku.SkuTerm} Years`
								}
							}

							const modifiedSku = {
								...sku,
								id: index + 1,
								IsPriceBookPricing:
									typeof sku.IsPriceBookPricing !== 'undefined'
										? sku.IsPriceBookPricing
										: true,
								AutoRenew: Number(sku.OrderSKUTermAutoRenewID) > 1,
								OrderSKUTermAutoRenewID: Number(sku.OrderSKUTermAutoRenewID),
								SkuTerm: isEmptyOrWhitespace(updatedSkuTerm)
									? sku.SkuTerm
									: Number(
											updatedSkuTerm.substring(0, updatedSkuTerm.indexOf(' '))
									  ) * MonthsInAYear,
								SkuEndDate: previousSKUActivationDate,
							} //entered IsPriceBookPricing initially to clear duplicate bug later
							return modifiedSku
						}
					)

					setCurrentOrderSummaryInfo(skuDetailsWithIds.map((el) => ({ ...el })))
					setOriginalOrderSummaryInfo(
						skuDetailsWithIds.map((el) => ({ ...el }))
					)
				}
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiCallsDone, orderSummaryResponse, partnerID])

	// Create columns based on the presence of TenantID
	const orderColumns: GenericColumn[] = [
		{
			ID: 'SkuTypeID',
			Label: 'SKU Name',
			IsEditable: false,
		},
		{
			ID: 'Quantity',
			Label: 'Quantity',
			IsEditable: false,
		},
		{
			ID: 'MRCPerUnit',
			Label: 'MRC/Unit',
			IsEditable: true,
		},
		{
			ID: 'NRCPerUnit',
			Label: 'NRC/Unit',
			IsEditable: true,
		},
		{
			ID: 'MRC',
			Label: 'Total MRC',
			IsEditable: false,
		},
		{
			ID: 'NRC',
			Label: 'Total NRC',
			IsEditable: false,
		},
		{
			ID: 'AutoRenew',
			Label: 'Auto Renew',
			IsEditable: false,
		},
		{
			ID: 'OrderSKUTermAutoRenewID',
			Label: 'Auto Renew Term',
			IsEditable: false,
		},
		{
			ID: 'SkuTerm',
			Label:
				orderSummaryResponse.OrderType === 'New' ||
				orderSummaryResponse.OrderType === 'Renewal'
					? 'Sku term (years)'
					: 'Sku term (months)',
			IsEditable: false,
		},
	]

	const getFXData = async () => {
		await getFXRates()
		await getBaseCurrency()
		// Stop loading
		setAPICallsDone(true)
	}

	// GET: Return Currency and FXRates
	const getFXRates = async () => {
		// Make call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'PriceBookManagement.tsx: getFXRates()',
			QueryURL: `GetV2?Params=Currency.All(), FxRate.All()`,
			ErrorMessage:
				'An error occurred when getting FX Rates and Currency information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// *** Get the all relevant lists and build the display array *** //
			var currencyResponseList = dataResponse.Obj.CurrencyList as Currency[]

			if (currencyResponseList && currencyResponseList.length > 0) {
				var availableCurrencyList = currencyResponseList.filter(
					(item) => item.CurrencyID !== Currencies.No_Currency
				)

				setDisplayCurrencyList(availableCurrencyList)

				var fxRateResponseList = dataResponse.Obj.FXRateList as FXRate[]

				if (fxRateResponseList && fxRateResponseList.length > 0) {
					setFXRateList(fxRateResponseList)
				}
			}
		}
	}

	// GET: BaseCurrency and Currency Values for Partner
	const getBaseCurrency = async () => {
		// Make call
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'PriceBookManagement.tsx: getBaseCurrency()',
			QueryURL: `GetV2?Params=CustomerPartner.Where(CustomerPartner.CustomerPartnerID = '${partnerID}')`,
			ErrorMessage: 'An error occurred when getting price book information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var customerPartnerResponseList = dataResponse.Obj
				.CustomerPartnerList as CustomerPartner[]
			if (
				customerPartnerResponseList &&
				customerPartnerResponseList.length > 0
			) {
				var _baseCurrencyID = customerPartnerResponseList[0]
					.CurrencyID as number
				setBaseCurrencyID(_baseCurrencyID)
				setOriginalOrderCurrencyID(_baseCurrencyID)
				setOrderCurrencyID(_baseCurrencyID)
			}
		}
	}

	//add order confirmation
	const handleAddOrderConfirmation = () => {
		setDialogTitle('Caution: This is an irreversible action!')
		setDialogDescription(`Are you sure you want to add this order? This purchase order is computer generated and requires no signature. 
    It will be legally binding and form part of the contract between the parties upon its acceptance.`)
		setOpenDialog(true)
	}

	//POST: Add new order
	const addNewOrder = async () => {
		setIsLoading(true)

		//Check if Currency Changed
		var finalOrderSummary = [] as SkuDetailsResponse[]

		if (orderCurrencyID !== baseCurrencyID) {
			//if changed, use the display data to save to the DB
			finalOrderSummary = currentOrderSummaryInfo.map((el) => ({ ...el }))
		} else {
			//if unchanged, use original data

			finalOrderSummary = originalOrderSummaryInfo.map((el) => ({ ...el }))
		}
		var maxSkuYears = 0

		var skuList: AddOrderSKUsModel[] = []
		for (var i = 0; i < finalOrderSummary.length; i++) {
			var skuTermInMonths: any = finalOrderSummary[i].SkuTerm
			//convert it to months and years
			var skuMonths = skuTermInMonths % MonthsInAYear
			var skuYears = Math.floor(skuTermInMonths / MonthsInAYear)
			if (skuYears > maxSkuYears) {
				maxSkuYears = skuYears
			}

			// Retrieve modified or original MRCPerUnit & NRCPerUnit from table
			var MRCPerUnit = currentOrderSummaryInfo[i].MRCPerUnit
			var NRCPerUnit = currentOrderSummaryInfo[i].NRCPerUnit

			// Get OrderSKUTermAutoRenewID
			var orderSKUTermAutoRenewID = Number(
				finalOrderSummary[i].OrderSKUTermAutoRenewID
			)

			var isNonStandardPricing = true

			if (finalOrderSummary[i] && finalOrderSummary[i].IsPriceBookPricing) {
				isNonStandardPricing = false
			}

			var skuObject: AddOrderSKUsModel = {
				Quantity: Number(finalOrderSummary[i].Quantity),
				SKU: finalOrderSummary[i]?.SkuTypeID + '',
				MRC: MRCPerUnit,
				NRC: NRCPerUnit,
				NonStandardPricing: isNonStandardPricing,
				AutoRenew: orderSKUTermAutoRenewID > OrderSKUAutoRenewTerm['No Term'],
				OrderSKUTermAutoRenewID: orderSKUTermAutoRenewID,
				SKUTerm: {
					Months: skuMonths,
					Years: skuYears,
				},
			}

			skuList.push(skuObject)
		}

		const getCurrencyName = (id: number) => {
			var currencyName = displayCurrencyList.find(
				(curr) => curr.CurrencyID === id
			)?.CurrencyName

			return currencyName
		}

		var addOrderRequest: AddOrderRequestModel = {
			IsTest: false,
			PartnerCustomerReference: customerPartnerReference,
			OrderType: orderSummaryResponse?.OrderType + '',
			PreviousOrderID: orderSummaryResponse?.PreviousOrderID,
			PurchaseOrder: orderSummaryResponse?.PurchaseOrder,
			PartnerOrderID: orderSummaryResponse?.PartnerOrderID + '',
			Currency: getCurrencyName(orderCurrencyID),
			OrderTerm: {
				Months: 0,
				Years: maxSkuYears,
			},
			SKUs: skuList,
			SalesAgentEmail: orderSummaryResponse?.SalesAgentEmail + '',
			CoTerm: isCoTerm,
			HasOrderNotes: hasOrderNotes,
		}
		// Check if order hasOrderNotes
		if (hasOrderNotes) {
			addOrderRequest.OrderNotes = orderNotes
		}

		var betaDataObj = await toBeta(addOrderRequest)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		dispatch(setPartnerIDToUse(partnerID))

		await addOrder(betaObj)
			.unwrap()
			.then(async (response) => {
				if (response?.Content) {
					var dataResponse = JSON.parse(
						await toAlphaString(response?.Content)
					) as AddOrderResponse

					if (dataResponse) {
						var orderID = dataResponse.SipcomOrderID
						showSuccessToast(`Order added successfully. OrderID: ${orderID}`)
						handleCloseOrderFormModal()
					}
				}
			})
			.catch(async (err) => {
				if (err) {
					var errorResponse = JSON.parse(await toAlphaString(err.data.Content))
					showErrorToast(
						'An error occurred when adding your order. Please contact support.'
					)
					await addErrorLog({
						ActionPerformed: `Add Order error: AddOrderSummary.tsx - Generic Portal`,
						ErrorInfo: `Add Order error: ${errorResponse}. Request: ${JSON.stringify(
							addOrderRequest
						)}`,
					})
				}
			})

		setIsLoading(false)
	}

	// *** Handle Functions *** //

	const handleCurrencyChange = (e: { target: any }) => {
		var selectedCurrencyID = Number(e.target.value)
		setOrderCurrencyID(selectedCurrencyID)

		//determine if conversion is to another currency or back to base
		if (selectedCurrencyID !== originalOrderCurrencyID) {
			var rate = fxRateList.find(
				(rate) =>
					rate.FromCurrencyID === Number(originalOrderCurrencyID) &&
					rate.ToCurrencyID === Number(selectedCurrencyID)
			)?.FXRate as Number

			if (rate) {
				//calculate values in new selected currency with decimal view

				var convertedValues = [] as SkuDetailsResponse[]

				originalOrderSummaryInfo.forEach((x) => {
					var mrcPerUnitRate = Number(x?.MRCPerUnit) * Number(rate)
					var nrcPerUnitRate = Number(x?.NRCPerUnit) * Number(rate)

					var newRow: SkuDetailsResponse = {
						MRC: Number(mrcPerUnitRate.toFixed(2)) * Number(x.Quantity),
						NRC: Number(nrcPerUnitRate.toFixed(2)) * Number(x.Quantity),
						MRCPerUnit: Number(mrcPerUnitRate.toFixed(2)),
						NRCPerUnit: Number(nrcPerUnitRate.toFixed(2)),
						SkuTypeID: x?.SkuTypeID,
						Formula: x?.Formula,
						IsPriceBookPricing: x?.IsPriceBookPricing,
						Quantity: x?.Quantity,
						SkuTerm: x?.SkuTerm,
						AutoRenew: x.AutoRenew,
						OrderSKUTermAutoRenewID: Number(x.OrderSKUTermAutoRenewID),
						SkuEndDate: x.SkuEndDate,
					}
					convertedValues.push(newRow)
				})

				setCurrentOrderSummaryInfo([...convertedValues])
			}
		} else {
			setCurrentOrderSummaryInfo(originalOrderSummaryInfo)
		}
	}

	const disableRadioButtonsOrderConfirmation = () => {
		setOriginalOrderSummaryInfo(currentOrderSummaryInfo)
		var convertedValues = [] as SkuDetailsResponse[]

		currentOrderSummaryInfo.forEach((x) => {
			var newRow: SkuDetailsResponse = {
				MRC: Number(Number(x?.MRC).toFixed(2)),
				NRC: Number(Number(x?.NRC).toFixed(2)),
				MRCPerUnit: Number(Number(x?.MRCPerUnit).toFixed(2)),
				NRCPerUnit: Number(Number(x?.NRCPerUnit).toFixed(2)),
				SkuTypeID: x?.SkuTypeID,
				Formula: x?.Formula,
				IsPriceBookPricing: x?.IsPriceBookPricing,
				Quantity: x?.Quantity,
				SkuTerm: x?.SkuTerm,
				AutoRenew: x.AutoRenew,
				OrderSKUTermAutoRenewID: Number(x.OrderSKUTermAutoRenewID),
				SkuEndDate: x.SkuEndDate,
			}
			convertedValues.push(newRow)
		})

		setCurrentOrderSummaryInfo([...convertedValues])
		setIsCurrencyRadioDisabled(true)
		setDisableOrderConfirmation(true)
	}

	const handleEditDecimalData = (data: SkuDetailsResponse[]) => {
		//to determine which field edited
		var isMRCPerUnitChanging = false
		var isNRCPerUnitChanging = false

		if (isMrcNrcChanging) {
			if (mrcNrcField === mrcPerUnitCheck) {
				isMRCPerUnitChanging = true
			}
			if (mrcNrcField === nrcPerUnitCheck) {
				isNRCPerUnitChanging = true
			}
		} else {
			isMRCPerUnitChanging = false
			isNRCPerUnitChanging = false
		}

		var newData = [] as OrderSummaryDisplay[]
		newData = data.map((row) => {
			var newObj = {
				SkuTypeID: row.SkuTypeID,
				Quantity: row.Quantity,
				SkuTerm:
					Number(row.SkuTerm) < MonthsInAYear || isCoTerm
						? row.SkuTerm
						: Math.ceil(Number(row.SkuTerm) / MonthsInAYear),
				NRCPerUnit:
					isNRCPerUnitChanging && row.SkuTypeID === mrcNrcRowChanged
						? mrcNrcValue
						: Number(row.NRCPerUnit)?.toFixed(2),
				MRCPerUnit:
					isMRCPerUnitChanging && row.SkuTypeID === mrcNrcRowChanged
						? mrcNrcValue
						: Number(row.MRCPerUnit)?.toFixed(2),
				NRC: row.NRC?.toFixed(2),
				MRC: row.MRC?.toFixed(2),
				Formula: row.Formula,
				IsPriceBookPricing: row.IsPriceBookPricing,
				AutoRenew: row.AutoRenew ? 'True' : 'False',
				OrderSKUTermAutoRenewID:
					OrderSKUAutoRenewTerm[Number(row.OrderSKUTermAutoRenewID)],
				SkuEndDate:
					row.SkuEndDate !== undefined
						? moment(row.SkuEndDate).format('YYYY/MM/DD')
						: row.SkuEndDate,
			}
			return newObj as OrderSummaryDisplay
		})

		return newData
	}

	const handleMrcNrcChange = (rowId: any, field: string, newValue: any) => {
		//states for error handling
		setIsMrcNrcChanging(true)
		setMrcNrcField(field)
		setMrcNrcValue(newValue)
		setMrcNrcRowChanged(rowId)

		var numMrcNRCValue = 0

		if (newValue.endsWith('.')) {
			numMrcNRCValue = Number(newValue.slice(0, -1))
		} else {
			numMrcNRCValue = Number(newValue)
		}

		setIsCurrencyRadioDisabled(true)

		//make a backup of the current values and ID before changing
		setOriginalOrderCurrencyID(orderCurrencyID)

		var changedArray = currentOrderSummaryInfo
			.map((prevRow) =>
				prevRow.SkuTypeID === rowId
					? {
							...prevRow,
							[field]: numMrcNRCValue,
							MRC:
								field === mrcPerUnitCheck
									? numMrcNRCValue * Number(prevRow.Quantity)
									: Number(prevRow.MRCPerUnit) * Number(prevRow.Quantity),
							NRC:
								field === nrcPerUnitCheck
									? numMrcNRCValue * Number(prevRow.Quantity)
									: Number(prevRow.NRCPerUnit) * Number(prevRow.Quantity),
							IsPriceBookPricing: false,
					  }
					: prevRow
			)
			.map((el) => ({ ...el })) as SkuDetailsResponse[]

		setCurrentOrderSummaryInfo(changedArray)
	}

	const handleSave = () => {
		//set the data to the changed values
		setIsMrcNrcChanging(false)

		setOriginalOrderCurrencyID(orderCurrencyID)
		setIsCurrencyRadioDisabled(false)
		setDisableOrderConfirmation(false)

		var baseVals = currentOrderSummaryInfo.map((el) => ({ ...el }))
		setOriginalOrderSummaryInfo(baseVals)
	}

	const handleCancelEdit = () => {
		//set the table back to original data
		var baseVals = originalOrderSummaryInfo.map((el) => ({ ...el }))
		setCurrentOrderSummaryInfo(baseVals)
		setIsCurrencyRadioDisabled(false)
		setIsMrcNrcChanging(false)
		setDisableOrderConfirmation(false)
	}

	return apiCallsDone ? (
		<>
			<Box>
				{/* Confirmation Popup */}
				<Dialog
					open={openDialog}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'>
					<DialogTitle id='alert-dialog-title'>{dialogTitle}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							{dialogDescription}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => setOpenDialog(false)}
							sx={{ color: '#db3d47' }}>
							Cancel
						</Button>
						<Button onClick={addNewOrder} autoFocus>
							{isLoading ? (
								<CircularProgress
									size={24}
									style={{ color: theme.palette.primary.main }}
								/>
							) : (
								'Confirm'
							)}
						</Button>
					</DialogActions>
				</Dialog>

				{/* Order Summary */}
				{orderSummaryResponse !== null && (
					<>
						<Box className='order-form-container'>
							{/* Heading */}
							<Box className='order-form-header'>
								<Icon
									path={mdiTextBoxEditOutline}
									size={2}
									style={{ color: theme.palette.primary.main }}
								/>
								<Typography id='order-form-header' component='h1'>
									New Order Summary{' '}
									<Typography component='p'>
										You can view your order summary here and confirm the
										details.{' '}
									</Typography>
								</Typography>
							</Box>

							<Divider />

							{/* Form Content */}
							<Box className='order-form-content-add'>
								{/* Order Details */}
								<Box className='order-form-pd'>
									<Box className='order-form-checkbox-container'>
										<Typography variant='h6'>Order Details:</Typography>
									</Box>

									<Box className='order-details-box'>
										<Box className='order-details-box'>
											<Typography component='h4'>Customer: </Typography>
										</Box>

										<Box className='order-details-box'>
											<Typography component='p'>
												{orderSummaryResponse.CustomerName}
											</Typography>{' '}
										</Box>

										<Box className='order-details-box'>
											{' '}
											<Typography component='h4'>
												Partner Order Reference:
											</Typography>
										</Box>

										<Box className='order-details-box'>
											<Typography component='p'>
												{orderSummaryResponse.PartnerOrderID}
											</Typography>{' '}
										</Box>

										<Box className='order-details-box'>
											<Typography component='h4'>Order Type: </Typography>
										</Box>

										<Box className='order-details-box'>
											<Typography component='p'>
												{orderSummaryResponse.OrderType}
											</Typography>
										</Box>

										{(orderSummaryResponse.OrderType === 'Modify' ||
											orderSummaryResponse.OrderType === 'Renewal' ||
											orderSummaryResponse.OrderType === 'TrueUp' ||
											isCoTerm) && (
											<>
												<Box className='order-details-box'>
													<Typography component='h4'>
														Previous Order ID:
													</Typography>
												</Box>

												<Box className='order-details-box'>
													<Typography component='p'>
														{orderSummaryResponse.PreviousOrderID}
													</Typography>
												</Box>
											</>
										)}

										{orderSummaryResponse.SalesAgentEmail === '' ? null : (
											<>
												<Box className='order-details-box'>
													<Typography component='h4'>
														Sales Agent Email:
													</Typography>
												</Box>

												<Box className='order-details-box'>
													<Typography component='p'>
														{orderSummaryResponse.SalesAgentEmail}
													</Typography>
												</Box>
											</>
										)}

										<Box className='order-details-box'>
											{' '}
											<Typography component='h4'>Purchase Order:</Typography>
										</Box>

										<Box className='order-details-box'>
											{' '}
											<Typography component='p'>
												{orderSummaryResponse.PurchaseOrder}
											</Typography>
										</Box>

										<Box className='order-details-box'>
											{' '}
											<Typography component='h4'>Co Term:</Typography>
										</Box>

										<Box className='order-details-box'>
											{' '}
											<Typography component='p'>
												{isCoTerm ? <CheckIcon /> : <CloseIcon />}
											</Typography>
										</Box>
									</Box>
								</Box>

								<Divider />

								{/* SKU Details */}
								<Box className='order-form-pd'>
									<Typography variant='h6'>SKU Details:</Typography>
									{/* Currency Change */}
									<Box className='order-currency-box'>
										<Box className='order-details-box'>
											<Typography component='h4'>Currency: </Typography>
										</Box>
										<FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
											<RadioGroup
												row
												name='row-radio-buttons-group'
												defaultValue={baseCurrencyID}
												onChange={handleCurrencyChange}>
												{displayCurrencyList.map((item, index) => (
													<FormControlLabel
														value={item.CurrencyID}
														control={
															<Radio
																disabled={
																	isCurrencyRadioDisabled ? true : false
																}
															/>
														}
														label={item.CurrencyName}
														labelPlacement='top'
													/>
												))}
											</RadioGroup>
											<InfoAlert
												severity='info'
												style={{
													fontSize: '12px',
													paddingBottom: '30px',
												}}
												sx={{
													'& .MuiAlert-icon': {
														color: theme.palette.primary.main,
													},
												}}>
												Please choose the desired currency for displaying the
												data in the table.
											</InfoAlert>
										</FormControl>
									</Box>

									{/* display sku details inside a table*/}
									<Box>
										{/* Generic Table */}
										<Box id='generictable'>
											<AddOrderSummaryTable
												data={handleEditDecimalData(currentOrderSummaryInfo)}
												columns={orderColumns}
												rowIdKey={orderColumns[0].ID}
												isEditable={true}
												onChange={handleMrcNrcChange}
												onSave={handleSave}
												onCancel={handleCancelEdit}
												isCoTerm={isCoTerm}
												previousSKUActivationDate={previousSKUActivationDate}
												disableRadioButtonsOrderConfirmation={
													disableRadioButtonsOrderConfirmation
												}
											/>
										</Box>
									</Box>
									<Box className='order-form-button'>
										<StyledButton
											disabled={disableOrderConfirmation}
											onClick={handleAddOrderConfirmation}>
											Confirm Order
										</StyledButton>
									</Box>
								</Box>
								<InfoAlert severity='info'>
									Order has not yet been created. Order will be created on
									confirmation.
								</InfoAlert>
							</Box>
						</Box>
					</>
				)}
			</Box>
		</>
	) : (
		// Show loading
		<LoadingBox title='Getting Order Summary' />
	)
}

export default AddOrderSummary
